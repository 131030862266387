.elsewhere {
  grid-area: 2/9/3/17;
  height: 100%;
}
@media (min-width: 1024px) {
  .elsewhere {
    grid-area: 2/3/22/16;
  }
}
.elsewhere .card {
  margin-right: auto;
}
@media (min-width: 1024px) {
  .elsewhere .card {
    margin: 0;
  }
}
.elsewhere__list {
  grid-area: 2/2/19/7;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  line-height: 2.5em;
}
@media (min-width: 1024px) {
  .elsewhere__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    font-size: calc(var(--desktopGridSquareSize) * 0.5);
    text-align: left;
    line-height: 1.7em;
  }
}
@media (min-width: 1024px) {
  .elsewhere-item {
    display: block;
    position: relative;
    width: calc(var(--desktopGridSquareSize) * 6);
    height: calc(var(--desktopGridSquareSize) * 6);
  }
}
@media (min-width: 1024px) {
  .elsewhere-item__link {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: calc(var(--desktopGridSquareSize) * 1);
  }
  .elsewhere-item__link:hover {
    color: #fbf8f6;
  }
  .elsewhere-item__link:active {
    background-color: #d2ceca;
  }
}
@media (min-width: 1024px) and (prefers-color-scheme: dark) {
  .elsewhere-item__link:hover {
    color: #020913;
  }
}
@media (min-width: 1024px) and (prefers-color-scheme: dark) {
  .elsewhere-item__link:active {
    background-color: #c5cad5;
  }
}
@media (min-width: 1024px) {
  .elsewhere-item__path {
    display: block;
  }
}
.elsewhere-item__domain {
  font-weight: 700;
}
.elsewhere-item-effects {
  --fill-color: #4476c3;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media (prefers-color-scheme: dark) {
  .elsewhere-item-effects {
    --fill-color: #ff8d1b;
  }
}
@media (min-width: 1024px) {
  .elsewhere-item-effects {
    display: block;
  }
}
.layout {
  --desktopGridSquareSize: 2.173913043478261vw;
  display: grid;
  box-sizing: border-box;
  height: 100%;
  grid-template-rows: 2.5vh 1fr 2.5vh 16px;
  grid-template-columns: 0 repeat(14, 10vw) 0;
  column-gap: 2.5vw;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  font-size: calc(var(--desktopGridSquareSize) * 2);
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  overflow: scroll;
  scroll-snap-type: x mandatory;
}
@media (min-width: 1024px) {
  .layout {
    height: auto;
    grid-template-rows: repeat(23, var(--desktopGridSquareSize));
    grid-template-columns: repeat(46, var(--desktopGridSquareSize));
    column-gap: 0;
    overflow: hidden;
    scroll-snap-type: none;
  }
}
@media (min-width: 1380px) {
  .layout {
    --desktopGridSquareSize: 30px;
  }
}
.layout section {
  scroll-snap-align: start;
  overflow: hidden;
}
.layout section .card {
  display: grid;
  box-sizing: border-box;
  width: 85vw;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-top-color: #d6d2ce;
  border-right-color: #cec4ba;
  border-bottom-color: #999087;
  border-left-color: #cec4ba;
  background: linear-gradient(to bottom, #d3cfcc, #ccc8c3);
  grid-template-columns: repeat(7, 10vw);
  grid-template-rows: repeat(22, 1fr);
  column-gap: 2.5vw;
  overflow: hidden;
}
@media (prefers-color-scheme: dark) {
  .layout section .card {
    background: linear-gradient(to bottom, #323438, #2b2d30);
    border-top-color: #3c3f43;
    border-right-color: #272b32;
    border-bottom-color: #1f2022;
    border-left-color: #272b32;
  }
}
@media (min-width: 1024px) {
  .layout section .card {
    display: block;
    width: auto;
    height: auto;
    border: none;
    background: none;
  }
}
.layout section .card > * {
  overflow: hidden;
}
.layout .robin-once-removed {
  grid-area: 2/10/3/15;
  align-self: end;
  margin-bottom: 6vh;
  font-style: italic;
  text-align: right;
}
@media (min-width: 1024px) {
  .layout .robin-once-removed {
    grid-area: 15/34/19/38;
    align-self: auto;
    margin: 0;
    font-size: calc(var(--desktopGridSquareSize) * 0.4);
    line-height: calc(var(--desktopGridSquareSize) * 1);
    text-align: left;
  }
}
.who-where {
  grid-area: 2/1/3/9;
  height: 100%;
}
@media (min-width: 1024px) {
  .who-where {
    grid-area: 4/20/19/44;
  }
}
.who-where .card {
  margin-left: auto;
}
@media (min-width: 1024px) {
  .who-where .card {
    display: flex;
  }
}
.who-where__name {
  grid-area: 2/1/18/8;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  padding-left: 0.34em;
  color: #fbf8f6;
  font-family: 'Grifo M Medium';
  font-size: 16vh;
  font-weight: normal;
  line-height: 0.9em;
  letter-spacing: -0.025em;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
@media (prefers-color-scheme: dark) {
  .who-where__name {
    color: #020913;
  }
}
@media (min-width: 1024px) {
  .who-where__name {
    margin-bottom: calc(var(--desktopGridSquareSize) * 1.8);
    padding-left: 0;
    writing-mode: unset;
    color: #554a45;
    font-size: calc(var(--desktopGridSquareSize) * 2.3);
    letter-spacing: calc(var(--desktopGridSquareSize) * 2.35);
    line-height: calc(var(--desktopGridSquareSize) * 4.6);
    text-transform: uppercase;
    transform: none;
  }
}
@media (min-width: 1024px) and (prefers-color-scheme: dark) {
  .who-where__name {
    color: #c5cad5;
  }
}
.who-where__first,
.who-where__last {
  display: block;
}
.who-where__title,
.who-where__location {
  font-size: 2.7vmin;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  letter-spacing: 0.9vw;
}
@media (min-width: 1024px) {
  .who-where__title,
  .who-where__location {
    display: inline-block;
    width: calc(var(--desktopGridSquareSize) * 4);
    height: calc(var(--desktopGridSquareSize) * 4);
    font-size: calc(var(--desktopGridSquareSize) * 0.4);
    letter-spacing: calc(var(--desktopGridSquareSize) * 0.052);
    line-height: calc(var(--desktopGridSquareSize) * 1);
    text-align: left;
  }
}
@media (prefers-color-scheme: dark) {
  .who-where__title,
  .who-where__location {
    font-weight: 600;
  }
}
.who-where__title {
  grid-area: 20/2/21/7;
}
.who-where__location {
  grid-area: 21/2/22/7;
}
@media (min-width: 1024px) {
  .who-where__location {
    margin-left: calc(var(--desktopGridSquareSize) * 3);
  }
}
@media (min-width: 1024px) {
  .who-where__comma {
    display: none;
  }
}
@font-face {
  font-family: 'Grifo M Medium';
  src: url("b286c9af78dacbb69b71834ac69917fb.woff") format('woff');
}
html,
body {
  margin: 0;
}
body {
  height: 100%;
  background-color: #efefee;
  color: #554a45;
}
@media (min-width: 1024px) {
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    font-size: 1.2vw;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #202020;
    color: #c5cad5;
  }
}
@media (min-width: 1024px) and (prefers-color-scheme: dark) {
  body {
    background-color: #2c2e31;
  }
}
body ::selection {
  background-color: rgba(37,104,207,0.5);
}
@media (prefers-color-scheme: dark) {
  body ::selection {
    background-color: rgba(255,141,27,0.5);
  }
}
a {
  color: #554a45;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  a {
    color: #c5cad5;
  }
}
a:hover,
a:active {
  color: #2568cf;
}
@media (prefers-color-scheme: dark) {
  a:hover,
  a:active {
    color: #ff8d1b;
  }
}
